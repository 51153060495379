<template>
  <b-modal v-model="modal" size="lg" hide-footer>
    <template #modal-title> BANCO DE JACKPOT - ACUMULADO </template>
    <div>
      <h5>Ruleta: {{ game.name }}</h5>
      <div class="d-flex justify-content-center">
        <h2>BANCO: {{ bank }}</h2>
      </div>
      <vs-button
        :loading="isFetching"
        class="mx-auto"
        @click="$emit('reload', game)"
        >ACTUALIZAR</vs-button
      >
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    game: {
      type: Object,
      default: () => ({
        name: "Ruleta",
        bank: 0,
      }),
    },
  },
  computed: {
    ...mapGetters({
      isFetching: "games/isFetching",
    }),
  },
  data() {
    return {
      modal: false,
      bank: 0,
    };
  },
};
</script>

<style lang="scss" scoped></style>
