<template>
  <b-modal v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      <h5>HORARIO DE APERTURA - CIERRE</h5>
    </template>
    <div>
      <b-row>
        <b-col sm="6">
          <label for="openingTime">APERTURA:</label>
          <b-form-timepicker
            id="openingTime"
            v-model="openingTime"
            locale="en"></b-form-timepicker>
        </b-col>
        <b-col sm="6">
          <label for="closingTime">CIERRE:</label>
          <b-form-timepicker
            id="closingTime"
            v-model="closingTime"
            locale="en"></b-form-timepicker>
        </b-col>
        <b-col>
          <vs-checkbox v-model="alwaysOpen">24 HRS</vs-checkbox>
        </b-col>
        <b-col>
          <vs-button class="mx-auto mt-4" :loading="loading" @click="save"
            >GUARDAR</vs-button
          >
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex/dist/vuex.common.js";

export default {
  props: ["game", "type"],
  computed: {
    ...mapGetters({
      formatTimeToUtc: "reports/formatTimeToUtc",
      success: "games/isSuccess",
    }),
  },
  data() {
    return {
      openingTime: "",
      closingTime: "",
      alwaysOpen: false,
      modal: false,
      loading: false,
    };
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        const data = {
          ...this.game,
          openingTime: this.openingTime,
          closingTime: this.closingTime,
          alwaysOpen: this.alwaysOpen,
        };

        await this.updateTime({ data, type: this.type });

        if (!this.success) {
          this.loading = false;
          return this.$swal.fire(
            "Error actualizando hora de apertura y cierre",
            "",
            "error"
          );
        }

        this.$swal.fire("Horario actualizado", "", "success");
        this.modal = false;
        this.loading = false;
        this.alwaysOpen = false;
        this.$emit("closeModal");
      } catch (error) {
        console.log("ERROR SAVING ROULETTE", error);
        this.loading = false;
        this.$swal.fire(
          "Error actualizando hora de apertura y cierre",
          "",
          "error"
        );
      }
    },
    ...mapActions({
      updateTime: "games/updateOpenClingTime",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
